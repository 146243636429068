const genRanHex = async size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

const ID_LEN = 32

const getStoredUserHex = async () => {
  let userHexId = localStorage.getItem('userHexId')
  if (!userHexId) {
    userHexId = await genRanHex(ID_LEN)
    localStorage.setItem('userHexId', userHexId)
  }

  return userHexId
}

export default getStoredUserHex